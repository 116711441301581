import { ConfirmDialog, Toast } from "@/lib/mixins";
import { router } from "@inertiajs/react";
import { state } from "@state/store";
import { fabric } from "fabric";
import { v4 as uuidv4 } from 'uuid';
import { JSONPostRequest } from "./JSONRequest";

export const encryptArray = (array) => {
    return new Promise((resolve, reject) => {
        JSONPostRequest({
            href: route('encrypt'),
            data: { ids: array },
            onResult: (data) => resolve(data?.encrypted),
            onLoading: () => { },
            onError: (error) => reject(error)
        });
    })
}

export const generateId = (length = 16) => {
    return `kirby${uuidv4().replace('-', '')}`.substring(0, length);
}

export const toast = async (message = "", type = "success") => {
    await Toast.fire({
        html: message || 'Success',
        icon: ['success', 'error', 'warning', 'info', 'question'].includes(type) ? type : 'success',
    })
}

export const lang = (string) => {
    //todo lang implementation
    return typeof string === 'string' ? string.trim() : "";
}

export const stripslashes = (value) => {
    return !value ? '' : value.replace(/\\(.)/mg, "$1");
}

export const setLoader = (val, text = null) => {
    state.processing_text = text || 'Processing...Please wait...';
    state.processing = val;
}

export const setComingSoon = (val) => {
    state.comingSoon = val;
}

export function hasImageOrText(canvas) {
    const allowedTypes = ['image', 'group', 'i-text', 'path', 'text'];
    const allowedObjectTypes = ['frame'];
    return canvas?.getObjects().some(obj => allowedTypes.includes(obj.type) || allowedObjectTypes.includes(obj.objectType));
}

export function hasFrame(canvas) {
    return canvas.getObjects().some(obj => obj.objectType === 'frame');
}

export function getFrame(canvas) {
    return canvas.getObjects().find(obj => obj.objectType === 'frame');
}

export function hasCanvasDesign(canvases) {
    let hasDesign = false;
    canvases.forEach(canvas => {
        if (hasImageOrText(canvas?.ref?.current)) {
            hasDesign = true;
        }
    });
    return hasDesign;
}

export const ucwords = (str) => {
    if (!str || typeof str !== 'string') return str;
    return str.replace('_', ' ').toLowerCase().replace(/(^|\s)\S/g, function (char) {
        return char.toUpperCase();
    });
}

export const localizeImage = async (url, type = 'localize', hasThumb = false) => {
    return new Promise((resolve, reject) => {
        JSONPostRequest({
            href: route('file.localize'),
            data: {
                image_url: url,
                type: type,
                thumb: hasThumb
            },
            onResult: (val) => resolve(val),
            onError: (val) => reject(val),
            onLoading: (val) => setLoader(val)
        })
    });
}

export const navigate = (url, method = "GET") => {
    // console.log(url);
    if (!url || url.includes('null') || url.includes('undefined')) return;
    try {
        router.visit(url, {
            method: method,
            onStart: () => setLoader(true),
            onFinish: () => setLoader(false)
        })
    } catch (error) {
        console.log('Err:navigate:' + error);
        console.log(`Err:navigate_URL:${url}|${method}`);
    }
}

export const configsToImage = async (options) => {
    if (!options) return undefined;

    const { product_width, product_height, image, top, left, width, height } = options;

    return new Promise((resolve) => {
        const canvas = document.createElement("canvas");
        const gradCanvas = new fabric.StaticCanvas(canvas, { width: product_width, height: product_height });

        fabric.Image.fromURL(image, (img) => {
            img.set({
                originX: 'center',
                originY: 'center',
                scaleX: product_width / img.width,
                scaleY: product_height / img.height,
            });
            gradCanvas.add(img);
            gradCanvas.centerObject(img);

            const editZone = new fabric.Rect({
                objectType: 'boundary',
                hasRotatingPoint: false,
                rx: 0, ry: 0,
                top, left, width, height,
                fill: 'transparent',
                selectable: false,
                stroke: 'black',
                strokeWidth: 5,
                strokeDashArray: [5, 5]
            });
            gradCanvas.add(editZone);

            const text = new fabric.Text('Design\nHere', {
                originX: 'center',
                originY: 'center',
                textAlign: 'center',
                fontFamily: 'Roboto',
                left: left + width / 2,
                top: top + height / 2,
            });
            text.scaleToWidth(width - 70);
            gradCanvas.add(text);

            gradCanvas.renderAll();

            // Use requestAnimationFrame for better performance
            requestAnimationFrame(() => {
                resolve(gradCanvas.toDataURL({
                    format: 'png',
                    quality: 0.5,
                }));
            });
        });
    });
};


export const adjustStage = (obj, newProductWidth, newProductHeight) => {
    const { width, height, top, left, aspect_ratio, product_width, product_height } = obj;

    // Calculate the change in product dimensions as a ratio
    const widthRatio = newProductWidth / product_width;
    const heightRatio = newProductHeight / product_height;

    // Apply the ratio to the original dimensions
    const newWidth = width * widthRatio;
    const newHeight = height * heightRatio;

    // Calculate new top and left positions
    const newTop = top * heightRatio;
    const newLeft = left * widthRatio;

    // Adjust the dimensions based on aspect ratio
    const adjustedWidth = newHeight * aspect_ratio <= newWidth ? newHeight * aspect_ratio : newWidth;
    const adjustedHeight = adjustedWidth / aspect_ratio;

    return {
        ...obj,
        width: adjustedWidth,
        height: adjustedHeight,
        top: newTop,
        left: newLeft,
        product_width: newProductWidth,
        product_height: newProductHeight
    };
}
export const adjustFrame = (obj, newImageWidth, newImageHeight) => {
    const { width, height, aspect_ratio, top, left, image_width, image_height } = obj;

    // Calculate the change in product dimensions as a ratio
    const widthRatio = newImageWidth / image_width;
    const heightRatio = newImageHeight / image_height;

    // Apply the ratio to the original dimensions
    const newWidth = width * widthRatio;
    const newHeight = height * heightRatio;

    // Calculate new top and left positions
    const newTop = top * heightRatio;
    const newLeft = left * widthRatio;

    // Adjust the dimensions based on aspect ratio
    const adjustedWidth = newHeight * aspect_ratio <= newWidth ? newHeight * aspect_ratio : newWidth;
    const adjustedHeight = adjustedWidth / aspect_ratio;

    return {
        ...obj,
        width: adjustedWidth,
        height: adjustedHeight,
        top: newTop,
        left: newLeft,
        aspect_ratio: (newImageWidth / newImageHeight)?.toFixed(2),
        image_width: newImageWidth,
        image_height: newImageHeight
    };
}

export const formatFileSize = (bytes) => {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0, n = parseInt(bytes, 10) || 0;

    while (n >= 1024 && ++l) {
        n = n / 1024;
    }

    return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`;
}

export const showImage = (image) => {
    if (!image) return;
    console.log(image)
    const w = window.open("");

    if (image.startsWith('<svg') || image.startsWith('<?xml')) {
        // Handle SVG string
        w.document.write(image);
    } else {
        // Handle regular image URL
        const img = new Image();
        img.onload = () => {
            w.document.write(img.outerHTML);
        };
        img.onerror = () => {
            w.document.write("Error loading image");
        };
        img.src = image;
    }

    w.document.close();
};

export const viewImage = (imageSrc) => {
    ConfirmDialog.fire({
        html: `<img src="${imageSrc}" class="w-full bg-gray-200 aspect-auto" />`,
        icon: null,
        showCancelButton: false,
        showConfirmButton: false,
    });
}

