import { v4 as uuidv4 } from 'uuid';

const CYAN_COLOR = '#0891b2';

const generateId = (length = 16) => {
    return `kirby${uuidv4().replace('-', '')}`.substring(0, length);
};

// Set default properties for fabric objects
export const setFabricDefaults = (fabric) => {
    const originalInitialize = fabric.Object.prototype.initialize;

    fabric.Object.NUM_FRACTION_DIGITS = 8

    fabric.Object.prototype.initialize = function (options = {}) {
        originalInitialize.call(this, options);
        if (!this.id) {
            this.id = generateId();
        }
    };

    Object.assign(fabric.Object.prototype, {
        transparentCorners: false,
        cornerStyle: 'circle',
        cornerColor: CYAN_COLOR,
        cornerSize: 15,
        cornerStrokeColor: CYAN_COLOR,
        borderColor: CYAN_COLOR,
        borderScaleFactor: 2,
        centeredScaling: true,
        borderDashArray: [2, 2],
    });

    Object.assign(fabric.Group.prototype, {
        originX: "center",
        originY: "center",
    });
};

// Memoized font fetching function
const createFetchAndExtractFonts = () => {
    const fontCache = new Map();

    return async (objs) => {
        const cssPromises = objs.map(async (obj) => {
            if (fontCache.has(obj.fontCss)) {
                return fontCache.get(obj.fontCss);
            }

            const response = await fetch(obj.fontCss);
            const cssText = await response.text();
            const fontFamilyClass = obj.fontFamily.replace(/\s+/g, '_').toLowerCase();
            const result = `
            ${cssText}
            .${fontFamilyClass} {
                font-family: "${obj.fontFamily}";
            }`;

            fontCache.set(obj.fontCss, result);
            return result;
        });

        const cssTexts = await Promise.all(cssPromises);
        return cssTexts.join('\n');
    };
};

// Modify toSVG methods for Text and IText
export function modifyToSVGMethod(prototype) {
    const originalToSVG = prototype.toSVG;
    prototype.toSVG = function (reviver) {
        const originalSVG = originalToSVG.call(this, reviver);
        const fontFamilyClass = this.fontFamily.replace(/\s+/g, '_').toLowerCase();
        return originalSVG.includes('class="')
            ? originalSVG.replace(/(class="[^"]*)(")/, `$1 ${fontFamilyClass}$2`)
            : originalSVG.replace('<text', `<text class="${fontFamilyClass}"`);
    };
};



export function customToSVG(options = {}) {
    const fontSources = new Set();

    this.getObjects().forEach(obj => {
        if (obj.type === 'i-text' && obj.fontCss) {
            fontSources.add(obj);
        }
    });

    const fetchAndExtractFonts = createFetchAndExtractFonts();

    const generateStyleTag = async () => {
        const cssContent = await fetchAndExtractFonts([...fontSources]);
        return `
        <style type="text/css">
            ${cssContent}
        </style>
      `;
    };

    const originalToSVG = async () => {
        const svgData = await new Promise((resolve) => {
            resolve(this.__proto__.__proto__.toSVG.call(this, options));
        });

        const styleTag = await generateStyleTag();
        return svgData.replace('<defs>', `<defs>${styleTag}`);
    };

    return originalToSVG();
}
